export const cookieSettings = {
    cookiename: "cookiesettings",
    cookieLifeTime: 1, // in months
};

export const cookieNoticeSettings = {
    centerMode: false,
    ignoredPages: [
        '/kontakt/datenschutz',
    ],
    hasDeclineButton: true,
    // backgroundColor: 'blue',
    // textColor: 'red',
    useBackdrop: false,
    backdropColor: '#333',
    backdropOpacity: '0.8',
    autoEnableMandatory: true,
    autoEnableMandatoryOnPlaceholder: true,
};

export const cookieNoticeTexts = {
    "de": {
        initialHeadline: 'Cookies, externe Dienste & Datenschutz',
        customizeHeadline: 'Ihre Datenschutz-Einstellungen',
        explanationText: "Nach Ihrer Zustimmung verwenden wir Cookies um die Anzeige zu optimieren. \n\n " +
            "Wir binden Skripte von Facebook und Youtuben ein. \n\n" +
            "Dabei können personenbezogene Daten an diese Anbieter übermittelt werden. Bitte klicken Sie <a href='/kontakt/datenschutz'>hier</a>, um Informationen zum Datenschutz zu erhalten.",
        accept_preset_ButtonText: 'Alle akzeptieren',
        declineButtonText: 'Ablehnen ',
        accept_all_ButtonText: 'Alle Cookies akzeptieren',
        decline_all_ButtonText: 'Alle ablehnen',
        customizeButtonText: 'Einstellungen',
        cancel_customizeButtonText: 'Zurück',
        saveButtonText: 'Speichern',
        closeButtonText: '×'
    },
    "en": {
        initialHeadline: 'Accept cookies & Privacy Policy?',
        customizeHeadline: 'Select cookies to accept',
        explanationText: "On your consent cookies are set to optimize your experience on our website. \n\n" +
            "We use [[ Google Analytics / Matomo Analytics ]] to analyze the usage  of our website. \n\n" +
            "We embed scripts from these external service providers: [[ YouTube, Vimeo, Google Maps, Mapbox, Facebook, Twitter, Instagram, ... ]] \n\n" +
            "On activation of one or more of these services personal data might be forwarded to these partners. Further information you find <a href='/kontakt/datenschutz'>here</a> in our privacy statement.",
        accept_preset_ButtonText: 'Accept all',
        declineButtonText: 'Decline',
        accept_all_ButtonText: 'Accept all',
        decline_all_ButtonText: 'Decline all',
        customizeButtonText: 'Customize',
        cancel_customizeButtonText: 'Go back',
        saveButtonText: 'Save',
        closeButtonText: '×'
    }
};

export const cookieSets = {
    "de": [
        {
            id: 'mandatory',
            name: 'Notwendige Cookies',
            description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig. Sie speichern keine personenbezogenen Daten.',
            mandatory: true,
            mandatory_text: "Diese Cookies sind notwendig, um Ihre Einstellungen zu speichern."
        }, {
            id: 'style',
            name: 'Styling Cookies',
            description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig (z.B. Schriften). Sie speichern keine personenbezogenen Daten.'
        }, {
            id: 'youtube_video',
            name: 'YouTube Video',
            description: 'Diese Skripte und Cookies sind nötig, um YouTube Videos auf dieser Seite abzuspielen. Durch das Aktivieren können personenbezogene Daten an YouTube übertragen werden.'
        }, {
            id: 'facebook',
            name: 'Facebook Stream inlc. Youtube Videos',
            description: 'Diese Skripte und Cookies sind nötig, um unseren Facebook-Stream inlc. Youtube Videos auf der Seite einzubinden. Durch das Aktivieren werden Skripte von Facebook eingebunden. Dabei können personenbezogene Daten an Facebook übertragen werden.'
        }],
    "en": [
        {
            id: 'mandatory',
            name: 'Essential cookies',
            description: 'Cookies that ensure the functionality and the design of our webpages. No personal data are collected. These cookies are required to save your session settings.',
            mandatory: true,
            mandatory_text: "These cookies are mandatory."
        }, {
            id: 'style',
            name: 'Styling Cookies',
            description: 'Cookies that ensure the design of our webpages (e.g. fonts). No personal data are collected.'
        },  {
            id: 'youtube_video',
            name: 'YouTube Video',
            description: 'Scripts and cookies that are required to play videos of this service on this website. On activation personal data might be forwarded to YouTube (Google).'
        }, {
            id: 'facebook',
            name: 'Facebook Stream inlc. Youtube Videos',
            description: 'Scripts and cookies that are required to integrate our Facebook stream on this website. On activation personal data might be forwarded to Facebook.'
        }]
};
